<template>
  <div class="position-relative">
    <div>
      <div class="bg-light">
        <div class="container py-5">
          <div class="row h-100 align-items-center py-5">
            <div class="col-12 col-lg-12 col-md-12 col-sm-12 mt-5">
              <h3 class="display-4 font-weight-bold text-white mt-5">Promotions</h3>
              <p class="lead text-white mb-5">
              Find out more about the latest promotion from us
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-around mt-5" v-if="loading">
        <loader-component></loader-component>
      </div>

      <div class="my-5"  v-if="!loading">
        <div class="container">
          <h4 class="text-center font-weight-bold mb-5">Our Latest Promotion</h4>
          <div class="row">
            <div
              class="col-12 col-md-4 mt-2"
              v-for="(promotion, p) in data"
              :key="p"
            >
              <div
                class="card shadow"
                @click.prevent="toDetail(promotion.slug)"
                style="cursor: pointer"
              >
                <img
                  class="card-img-top-blog"
                  :src="promotion.file_endpoint"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title text-left">
                    {{ promotion.title }}
                  </h5>
                  <p class="card-text text-left">
                    {{ promotion.description }}
                  </p>
                </div>
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <p
                      style="font-size: 13px"
                      class="badge badge-success px-3 py-2 b-radius"
                    >
                      Hingga
                      {{ moment(promotion.expired_at).format("DD-MM-YYYY") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderComponent from "../../components/Loader.vue";
export default {
  name: "About",
  components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
    };
  },

  mounted() {
    this.getAllPromotion();
  },

  methods: {
    getAllPromotion() {
      this.loading = true;
      const endpoint = "promotion";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.promotion;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    toDetail(slug) {
      this.$router.push("/promotions/" + slug);
    },
  },
};
</script>
